@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: "Google Sans";
    src: local("Google Sans Medium"), local("GoogleSans-Medium"),
        url("fonts/GoogleSans-Medium.woff2") format("woff2"),
        url("fonts/GoogleSans-Medium.woff") format("woff");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Google Sans";
    src: local("Google Sans Bold"), local("GoogleSans-Bold"),
        url("fonts/GoogleSans-Bold.woff2") format("woff2"),
        url("fonts/GoogleSans-Bold.woff") format("woff");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

html {
    scroll-behavior: smooth;
    /* overflow-x: hidden; */
    /* @apply bg-slate-950; */
    @apply bg-gray-900;
    /*CHANGE THIS STUFF TO IN COMPONENT STYLING*/
    color: white;
    font-family: "Google sans";

    @media (min-width: 768px) {
    font-size: 14px;
    }
    @media (min-width: 1024px) {
    font-size: 15px;
    }
    @media (min-width: 1536px) {
    font-size: 18px;
    }
    @media (min-width: 1800px) {
    font-size: 20px;
    }
    @media (min-width: 2000px) {
    font-size: 22px;
    }
}

#root{
    overflow-x: hidden;
}

::-webkit-scrollbar {
    width: 0.4rem;
}

::-webkit-scrollbar-track {
    background: transparent;
}

::-webkit-scrollbar-thumb {
    /* background: rgba(112,0,255,.75); */
    /* background: rgba(59,130,246,.75); */
    /* background: #2193b0; */
    background: white;
    border-radius: 1rem;
}

::-webkit-scrollbar-thumb:hover { 
    /* background: rgba(112,0,255,1); */
    /* background: rgba(59,130,246,1); */
    /* background: #6dd5ed; */
    background: linear-gradient(0deg,#6dd5ed,#2193b0);
}

.progress-bar{
    height: 0.2rem;
}

.section-container {
    @apply 2xl:container xl:px-20 md:px-12 px-4 mx-auto;
}

.menu-visible{
    visibility: visible;
    opacity:1;
    transition:visibility 0.3s linear,opacity 0.3s linear;
}

.menu-hidden{
    visibility: hidden;
    opacity: 0;
    transition:visibility 0.3s linear,opacity 0.3s linear;
}

.menu{
    backdrop-filter: blur(0.625rem);
    -webkit-backdrop-filter: blur(0.625rem);
}
 
.text-gradient-transition:hover{
    a{
        background: linear-gradient(
              90deg,
              #ffffff 0%,
              #ffffff 50%,
              #6dd5ed 51%,
              #2193b0 100%
            );
            background-size: 200% 100%;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;

            &:hover,
            &:focus {
              background-position: 100% 0;
            }
    }
}

.text-gradient {
    background: linear-gradient(90deg, #6dd5ed 0%, #2193b0 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.ProjectTile {

    height: 22rem;
    width: 38rem;
    background: black;
    transform-style: preserve-3d;
    transform: perspective(1000px);
  
    @media (min-width: 768px) {
      height: 26rem;
    }
  
    img {
      object-fit: cover;
    }
}

.ProjectImg {
    @apply absolute top-0 rounded-xl shadow-xl;

    width: 16.8rem !important;
    transform: rotate(-22.5deg) translateY(-0%);
    height: 30rem !important;
    min-width: unset !important;
    max-height: unset !important;
    object-fit: contain !important;
    left: unset !important;
    right: 2rem !important;
    bottom: unset !important;
}

.projectSpan {
    box-sizing:border-box;
    display:block;
    overflow:hidden;
    width:initial;
    height:initial;
    background:none;
    opacity:1;
    border:0;
    margin:0;
    padding:0;
    position:absolute;
    top:0;
    left:0;
    bottom:0;
    right:0;
}

.techIcons {
    transform: rotate(-22.5deg) translateZ(2rem);
}

.text-strong {
    background: linear-gradient(
        90deg,
        #ffffff 0%,
        #ffffff 50%,
        #6dd5ed 51%,
        #2193b0 100%
    );
    background-size: 200% 100%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

footer {
    background: url("./img/footer-bg.svg"),
        linear-gradient(
        153.86deg,
        #02494c 0%,
        #016877 15.69%,
        #0d576d 48.9%,
        #004865 95.52%
        );
}

.primary-button {
    @apply text-gray-900 py-2 px-7 font-medium rounded text-base md:text-xl tracking-wide  duration-300 flex items-center;

    background: linear-gradient(90deg, #2193b0 0%, #6dd5ed 50%, #2193b0 100%),
        linear-gradient(0deg, #ffffff, #ffffff);
    background-size: 200% 100%;

    &:hover {
        background-position: 100% 0;
    }
}

.outline-button {
    @apply border-white border-2 py-2 px-7 font-medium rounded text-base md:text-xl tracking-wide  duration-300 flex items-center;

    &:hover {
        @apply bg-white text-gray-900 opacity-90;
    }
}

.white-button {
    @apply bg-white text-gray-900 py-2 px-7 font-medium rounded text-base md:text-xl tracking-wide  duration-300 flex items-center;

    &:hover {
        @apply opacity-90;
    }
}

.section-heading {
    @apply md:text-5xl text-4xl font-bold text-gradient w-fit;
}

.section-title-sm {
    @apply uppercase tracking-widest text-gray-200 text-sm;
}

.skill {
    padding-right: 1.25rem !important;
    padding-bottom: 1.25rem !important;
}